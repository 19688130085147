/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createApiStore} from '../lib/store';
import StoreUtils from '../utils/StoreUtils';
import Constants from '../constants';

let blockedTraffic = [];
let count = {};

function setBlockedTraffics(data) {
  blockedTraffic = data;
}

function getBlockedTraffic(href) {
  return _.find(blockedTraffic, event => event.href === href);
}

function setCount(data) {
  count = data;
}

export default createApiStore(['BLOCKED_TRAFFICS_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.BLOCKED_TRAFFICS_GET_COLLECTION_SUCCESS:
        setBlockedTraffics(action.data);
        setCount(action.count);
        break;

      case Constants.BLOCKED_TRAFFICS_GET_INSTANCE_SUCCESS:
        StoreUtils.setObject(action.data, blockedTraffic);
        break;

      case Constants.BLOCKED_TRAFFICS_QUERIES_SUCCESS:
        setBlockedTraffics(action.data);
        count.total = action.data.length;
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAll() {
    return blockedTraffic;
  },

  get(href) {
    return href ? getBlockedTraffic(href) : null;
  },

  getCount() {
    return count;
  },
});
