/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import {createStore} from '../lib/store';
import dispatcher from '../actions/dispatcher';
import Constants from '../constants';
import ConnectivityStore from './ConnectivityStore';
// TODO: FIX THIS, RulesetStore doesn't have the rulesets needed
import RulesetStore from './RulesetStore';
import {ruleUnchanged} from '../utils/RulesetUtils';
import {RulesetUtils} from '../utils';

let length = 0;

function setLength(val) {
  length = val;
}

function getRuleUniqueId(rule) {
  return rule.href.split('/').slice(-3).join('/');
}

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.SEC_POLICY_ALLOWS_GET_COLLECTION_SUCCESS:
        dispatcher.waitFor([ConnectivityStore.dispatchToken]);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getRulesLength() {
    return length;
  },

  getAll(version) {
    return getAll(version);
  },
});

function getAll() {
  const {draft, active} = ConnectivityStore.getAll();

  if (!draft.length && !active.length) {
    return [];
  }

  const allRules = [];
  const draftRulesObj = {};
  const activeRulesObj = {};

  // Display the draft rules over the active rules always
  draft.forEach(rule => {
    draftRulesObj[getRuleUniqueId(rule)] = RulesetUtils.addUsageEntities(rule);
  });

  active.forEach(rule => {
    const ruleUnique = getRuleUniqueId(rule);

    activeRulesObj[ruleUnique] = RulesetUtils.addUsageEntities(rule);

    if (draftRulesObj[ruleUnique]) {
      // If the active rule exists in the draft rules array as well
      const draftRule = draftRulesObj[ruleUnique];

      if (draftRule.update_type === 'delete') {
        // 'delete' rules show up in draft API response as well
        allRules.push(draftRule);
      } else if (ruleUnchanged(draftRule, rule)) {
        // If the the draft and active rule are the same, push it as is
        allRules.push({...draftRule, update_type: null});
      } else {
        // Else mark is as updated
        allRules.push({...draftRule, update_type: 'update'});
      }
    }
  });

  draft.forEach(rule => {
    // If the draft rule doesn't exist in the active rules array, it has been added
    if (!activeRulesObj[getRuleUniqueId(rule)]) {
      allRules.push({...rule, update_type: 'create'});
    }
  });

  setLength(allRules.length);

  // Object to store all the draft rulesets
  const rulesets = {};

  allRules.forEach(rule => {
    const rulesetHref = rule.href.slice(0, rule.href.indexOf('/sec_rules')).replace('active', 'draft');

    // Load the draft ruleset if it doesn't already exist
    // If the ruleset doesn't exist in draft (that is the Ruleset is 'Deletion Pending'), then fallback to the active Ruleset
    rulesets[rulesetHref] = rulesets[rulesetHref] || {...RulesetStore.getSpecified(rulesetHref, 'rule_set_scopes')} || {
        ...RulesetStore.getSpecified(rulesetHref.replace('draft', 'active'), 'rule_set_scopes'),
      };

    rulesets[rulesetHref].matchedRules ||= [];
    rulesets[rulesetHref].matchedRules.push(rule);
  });

  return Object.values(rulesets);
}
